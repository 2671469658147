import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Navbar from './Navbar';
import Footer from './Footer';
import HamburgerNav from './HamburgerNav.js';
import LivePictures from './Pages/LivePictures.js';
import StudioPictures from './Pages/StudioPictures.js';
import Releases from './Pages/Releases.js';
import Shop from './Pages/Shop.js';
import Product from './Pages/Product.js';
import CommandCenter from './CommandCenter.js';
import FloatingControl from './FloatingControl.js'
import ScrollToTop from './Pages/components/ScrollToTop.js';
import ShopContextLayout from './layouts/ShopContextLayout.js';
import Cart from './Pages/Cart.js';
import getOS from './Pages/components/GetOS.js';
import BackToTop from './Pages/components/BackToTop.js'
import { analytics } from './Firebase.js';
import { logEvent } from 'firebase/analytics';

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    // Log a page view event with the current path
    logEvent(analytics, "page_view", { page_path: location.pathname });
  }, [location]);

  return null; // This component doesn't render anything
};

function App() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [scrollY, setScrollY] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const os = getOS();
  let scrollSpeed = os === 'Windows'? .175: .1;
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = (event) => {
      if (Math.abs(event.deltaY) > Math.abs(event.deltaX)) {
        event.preventDefault();
        window.scrollBy({
          top: event.deltaY * scrollSpeed,  // Reducing vertical scroll speed by 50%
          behavior: 'auto'
        });
      }
            // No need for event.preventDefault here to preserve horizontal scroll
    };
  
    window.addEventListener('wheel', handleScroll, { passive: false });
  
    return () => window.removeEventListener('wheel', handleScroll);
  }, []);
  

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        document.title = 'Home - Disco Misr';
        break;
      case '/about':
        document.title = 'About - Disco Misr';
        break;
      case '/bookings':
        document.title = 'Bookings - Disco Misr';
        break;
      case '/LivePictures':
        document.title = 'Live Pictures - Disco Misr';
        break;
      case '/Releases':
        document.title = 'Releases - Disco Misr';
        break;
      default:
        document.title = 'Disco Misr';
    }
  }, [location]);

  return (
    <div className="container">
      {/* <h1 style={{ position: 'fixed', color: 'white', fontSize: 32, zIndex: 9999 }}>{scrollY}</h1> */}
      {/* <h1 style={{ position: 'fixed', color: 'white', fontSize: 32, zIndex: 9999, top: '5%' }}>{windowWidth}</h1> */}
      <TrackPageView/>
      <ScrollToTop />
      {/* <FloatingControl/> */}
      {isMobile ? <HamburgerNav /> : (location.pathname === '/' || location.pathname === '/home' || location.pathname === '/bookings' ? <Navbar /> : <HamburgerNav />)}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/LivePictures" element={<LivePictures />} />
        <Route path="/bookings" element={<Home scrollToForm />} />
        <Route path="/StudioPictures" element={<StudioPictures />} />
        <Route path="/Releases" element={<Releases />} />
        {/* <Route path='/shop' element={<ShopContextLayout />}>
          <Route path="" element={
            <div>
              <CommandCenter />
              <Shop />
            </div>
          } />
          <Route path="product/:productId" element={
            <div>
              <CommandCenter />
              <Product />
            </div>
          } />
          <Route path="cart" element={
            <div>
              <CommandCenter />
              <Cart />
            </div>
          } />
        </Route> */}
      </Routes>
      <Footer />
      <BackToTop/>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './Pages/stylesheets/Navbar.css';
import MiniLogo from './Pages/assets/MiniLogo.webp';

export default function Navbar() {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();

  const toggleGalleryDropdown = () => {
    setIsGalleryOpen(!isGalleryOpen);
  };

  const handleBookingsClick = () => {
    navigate('/bookings');
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/" id='MiniLogoArea'>
          <img src={MiniLogo} alt="Logo"/>
        </Link>
      </div>
      <ul className="navbar-links" style={{ marginTop: `${Math.max(2 - scrollPosition * 0.5, 0)}%`,}}>
        <li>
          <Link to="/about">ABOUT</Link>
        </li>
        <li className="dropdown">
          <span onClick={toggleGalleryDropdown} className="dropdown-toggle">
            GALLERY <FontAwesomeIcon icon={isGalleryOpen ? faChevronUp : faChevronDown} className="dropdown-icon" />
          </span>
          <ul className={`dropdown-menu ${isGalleryOpen ? 'show' : ''}`}>
            <li>
              <Link to="/LivePictures">Live Photos</Link>
            </li>
            <li>
              <Link to="/StudioPictures">Studio Photos</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/Releases">RELEASES</Link>
        </li>
        <li>
          <span onClick={handleBookingsClick}>BOOKINGS</span>
        </li>
        {/* <li>
          <Link to="/Shop">SHOP</Link>
        </li> */}
      </ul>
    </nav>
  );
}
